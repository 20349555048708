@import '../../styles/customMediaQueries.css';

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  /* height: 100vh;  */
  /* ensures sufficient padding for hero components */
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding: 32px 0;

  @media (--viewportMedium) {
    padding: 83px 0;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding: 60px;
  }
}

.info {
  flex-grow: 1;
  justify-content: center;
  padding: 32px 0;

  @media (--viewportMedium) {
    padding: 83px 0;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding: 60px 0;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
  list-style: none;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
/*
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}*/

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  /* margin: var(--LandingPage_sectionMarginTop) 24px; */

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 24px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 24px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
.flex {
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}
.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}
.title h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  /* letter-spacing: -0.5px; */
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  color: var(--marketplaceColorDark);
  width: 350px;
  letter-spacing: 2px;
  line-height: 40px;
  margin-top: 0;
  padding: 30px;
  background-image: url('../LandingPage/image/Dotted_rectangle.png');

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    /* letter-spacing: -1px; */
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 400px) {
    background-size: cover;
  }
}

/* A bar on top of light sections */
/*
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;
*/

/* Place the bar on top of .sectionContent top margin */
/*
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }

}
*/
.bookingCard {
  margin: 25px;
  border-radius: 25px;
  margin-bottom: 1em;
  border: 4px solid var(--successColor);
  min-width: 400px;
  display: flex;

  .info {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    gap: 20px;
  }

  .refDateTime {
    display: flex;
    flex-direction: column;
    padding-right: 3em;
  }

  .date {
    @media (--viewportMedium) {
      padding-top: 10px; /* TODO in flex */
    }
  }

  .time {
    margin: 0px;
    gap: 1em;
    display: flex;
    flex-direction: row;
  }

  .bookingDetails {
    max-width: 100px;
    min-width: 100px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 150px;
      min-width: 150px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 170px;
      min-width: 170px;
      padding-right: 2em;
    }
  }

  .location {
    max-width: 125px;
    min-width: 125px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 200px;
      min-width: 200px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 200px;
      min-width: 200px;
      padding-right: 2em;
    }
  }

  .locationName {
    max-width: 150px;
    min-width: 150px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 225px;
      min-width: 225px;
      padding-right: 3em;
    }
    @media (--viewportLarge) {
      max-width: 225px;
      min-width: 225px;
      padding-right: 3em;
    }
  }

  .cpInfo {
    max-width: 100px;
    min-width: 100px;
    padding-right: 2em;

    @media (--viewportMedium) {
      max-width: 150px;
      min-width: 150px;
      padding-right: 2em;
    }
    @media (--viewportLarge) {
      max-width: 170px;
      min-width: 170px;
      padding-right: 2em;
    }
  }
}

.bookingButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 260px;

  /* Margin */
  margin-bottom: 48px;
}

.contentBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
